import React, {useEffect, useState} from "react";
import GreyBox from "../Components/Common/GreyBox";
import {
    Autocomplete,
    TextField,
    Button,
    Typography,
    CardContent,
    Card,
    Chip,
    Alert,
    AlertTitle,
    CircularProgress,
    LinearProgress,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import axiosInstance from "../AxiosInstance/axios";

const EditableField = ({ fieldType="text", label, value, onSave, size = "small" }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newValue, setNewValue] = useState(value);
    const [newTags, setNewTags] = useState([])

    const [professions, setProfessions] = useState([])
    const [roles, setRoles] = useState([])
    const [interests, setInterests] = useState([])
    const [researchPools, setResearchPools] = useState([])
    const [universities, setUniversities] = useState([])

    useEffect(() => {
        getDropdownValues("api/details/getProfessions", setProfessions, "professions")
        getDropdownValues("api/details/getInterests", setInterests, "interests")
        getDropdownValues("api/details/getPools", setResearchPools, "pools")
        getDropdownValues("api/details/getUniversities", setUniversities, "unis")
        getDropdownValues("api/details/getRoles", setRoles, "roles")
    }, []);

    const handleEdit = () => {
        setIsEditing(true);
        setNewValue(value)
    }

    const handleSave = (fType) => {
        if (fType === "normal") {
            onSave(newValue)
        } else {
            onSave(newTags)
        }
        
        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    function getOptions(label){ 
        if (label === "Discipline"){
            return professions
        } else if (label === "Institution"){
            return universities
        } else if (label === "Role"){
            return roles
        } else if (label === "Research Network"){
            return researchPools
        } else if (label === "Interests"){
            return interests
        } else if (label === "Opt-In for Email Marketing?"){
            return [
                {label: "Yes", category: true},
                {label: "No", category: false}
            ]
        }
    }

    const updateTags = (tags) => {
        setNewTags({...newTags, tags})
    }

    function getDropdownValues(endpoint, setterFunction, key){
        axiosInstance.get(endpoint)
          .then(response => {
            // Handle the response data here
            setterFunction(response.data[key])
          })
          .catch(error => {
            // Handle errors here
            console.error('Error in MyProfile.js: ', error);
          });
    }

    return (
        <div style={{marginBottom:"20px"}}>
            {isEditing ? (
                <>
                    {fieldType === "dropdown" && (
                        <div>
                            <Autocomplete 
                                options={getOptions(label)}
                                groupBy={(option)=>option.category}
                                getOptionLabel={(option) => option.label}
                                filterSelectedOptions
                                onChange={(event, newV)=>{setNewValue(newV)}}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label={label}
                                    />
                                )}
                            />
                            <Button variant="contained" color="primary" onClick={() => handleSave("normal")} size={size}>Save</Button>
                            <Button variant="outlined" color="secondary" onClick={handleCancel} size={size}>Cancel</Button>
                        </div>
                    )}

                    {fieldType === "dropdown-opt" && (
                        <div>
                            <Autocomplete 
                                options={getOptions(label)}
                                isOptionEqualToValue={(option, value) => option.category === value}
                                //groupBy={(option)=>option.category}
                                //getOptionLabel={(option) => option.label}
                                //filterSelectedOptions
                                onChange={(event, newV)=>{setNewValue(newV)}}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label={label}
                                    />
                                )}
                            />
                            <Button variant="contained" color="primary" onClick={() => handleSave("normal")} size={size}>Save</Button>
                            <Button variant="outlined" color="secondary" onClick={handleCancel} size={size}>Cancel</Button>
                        </div>
                    )}

                    {fieldType === "tag" && (
                        <div >
                            <Autocomplete
                                multiple
                                id="autocomplete-interests"
                                options={[]}
                                freeSolo={newTags.tags?(newTags.tags.length > 4 ? false : true):true}
                                onChange={(e,newV)=>updateTags(newV)}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        const {key, ...props} = getTagProps({ index });
                                        return <Chip variant="outlined" label={option} key={key} {...props} />
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Type up to 5 terms:"
                                    placeholder={newTags.tags
                                                ? `${5-newTags.tags.length} remaining`
                                                : '5 remaining'}
                                    />
                                )}
                            />
                            <Button variant="contained" color="primary" onClick={() => handleSave("tag")} size={size}>Save</Button>
                            <Button variant="outlined" color="secondary" onClick={handleCancel} size={size}>Cancel</Button>
                        </div>
                    )}
                    
                    {fieldType === "text" && (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'space-between' }}>
                            <TextField label={label} value={newValue} onChange={e => setNewValue(e.target.value)} style={{flexGrow:1}} multiline maxRows={10} />
                            <Button variant="contained" color="primary" onClick={() => handleSave("normal")} size={size}>Save</Button>
                            <Button variant="outlined" color="secondary" onClick={handleCancel} size={size}>Cancel</Button>
                        </div>
                    )}
                </>
            ) : (
                <>
                {fieldType === "tag" ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'space-between', }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
                            <Typography><b>Interests:</b></Typography>
                            {value !== undefined  && (
                                <>
                                {value.map((keyword, index) => (
                                    <Chip key={index} label={keyword.trim()} style={{margin: '2px'}} />
                                ))}
                                </>
                            )}
                        </div>
                        <Button variant="outlined" color="primary" onClick={() => handleEdit("tag")} size={size}>Change</Button>
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'space-between', }}>
                        <Typography><b>{label}{typeof value !== 'boolean' && ':'}</b> {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value} </Typography>
                        <Button variant="outlined" color="primary" onClick={handleEdit} size={size}>Change</Button>
                    </div>
                )}
                </>
            )}
        </div>
    );
};

const MyProfile = () => {

    const [userDetails, setUserDetails] = useState({});
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [formErrors, setFormErrors] = useState([]);
    const [passwordError, setPasswordError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        getLoggedInUserDetails();
    }, []);  

    const handlePasswordChange = async () => {
        //send post request to change password
        if (await validatePasswordFields()) {
            try {
                setLoading(true)
                await axiosInstance.post("api/changePassword", 
                    {newPassword: newPassword}, 
                    {headers: {'Content-Type': 'application/json'}
                })
                .then(response => {
                    setSuccess(true)
                    setIsChangingPassword(false)
                })
            } catch (error) {
                console.log(error.response.status)
                console.error(error)
            } finally {
                setLoading(false)
            }

        }
        
    }

    const validatePasswordFields = async () => {
        setFormErrors([])
        let errors = []

        // Check if all fields are filled
        if (currentPassword !== "" && newPassword !== "" && confirmPassword !== "") {

            // Check if new password and confirm password match
            if (newPassword !== confirmPassword) {
                setPasswordError(true)
                return false
            } else {
                setPasswordError(false)
            }

            // Check if current password is correct
            try {
                setLoading(true)
                await axiosInstance.post("api/verifyPassword", 
                    {
                    email: userDetails.email,
                    password: currentPassword
                    }, 
                    {headers: {'Content-Type': 'application/json'}
                })
                .then(response => {
                    if (response.status !== 200) {
                        errors.push("Current password is incorrect.")
                    } 
                    // otherwise continue as current password and password entered match
                })
            } catch (error) {
                if (error.response.status === 400) {
                    errors.push("Current password is incorrect.")
                } else {
                    errors.push("An error occurred. Please try again later.")
                    console.error(error)
                }
            } finally {
                setLoading(false)
            }
            
            // Check if new password is different from current password
            try {
                setLoading(true)
                await axiosInstance.post("api/verifyPassword", 
                    {
                    email: userDetails.email,
                    password: newPassword
                    }, 
                    {headers: {'Content-Type': 'application/json'}
                })
                .then(response => {
                    if (response.status === 200) {
                        errors.push("New password entered is the same as current password.")
                    } 
                })
            } catch (error) {
                if (error.response.status !== 400) {
                    errors.push("An error occurred. Please try again later or log in again.")
                    console.error(error)
                }
            } finally {
                setLoading(false)
            }

        } else {
            errors.push("All fields must be filled out.")
        }

        if (errors.length > 0) {
            setFormErrors(errors);   
            return false
        } else {
            return true
        }

    }

    const getLoggedInUserDetails = async () => {
        await axiosInstance.get("api/generic/userDetails")
        .then(response => {
            setUserDetails(response.data)
        })
    };

    const handleSave = async (field, newValue) => {
        const data = {
            field: field,
            newValue: newValue
        }
         
        await axiosInstance.post("api/generic/userDetails", data, {headers: {'Content-Type': 'application/json'}})
        .then(response => {
            getLoggedInUserDetails();
        })
    };

    const manageChangingPassword = (passwordState) => {
        setSuccess(false)
        resetPasswordFields()
        setIsChangingPassword(passwordState)
    }

    const resetPasswordFields = () => {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmPassword("")
        setFormErrors([])
    }

    return (
        <GreyBox>
            <br/>
            
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',}}>
                <Card style={{ minWidth: '550px', minHeight: '200px' }}>
                    <CardContent>
                        <Typography variant="h3" component="div" style={{marginBottom: "20px"}}>
                            My Profile
                        </Typography>
                        
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'space-between', marginBottom:"20px"}}>
                            <Typography> <b>Email</b>: {userDetails.email}</Typography>
                        </div>
                        <EditableField fieldType="dropdown" label="Discipline" value={userDetails.profession} onSave={newValue => handleSave('profession', newValue)} />
                        <EditableField fieldType="dropdown" label="Institution" value={userDetails.workplace} onSave={newValue => handleSave('workplace', newValue)} />
                        <EditableField fieldType="tag" label="Interests" value={userDetails.interests} onSave={newValue => handleSave('interests', newValue)} />
                        <EditableField label="LinkedIn" value={userDetails.linkedIn === "" ? "N/A" : userDetails.linkedIn} onSave={newValue => handleSave('linkedIn', newValue)} />
                        <EditableField fieldType="dropdown" label="Role" value={userDetails.role} onSave={newValue => handleSave('role', newValue)} />
                        <EditableField fieldType="dropdown"  label="Research Network" value={userDetails.researchPool}  onSave={newValue => handleSave('researchPool', newValue)} />
                        <EditableField fieldType="dropdown-opt" label="Opt-In for Email Marketing?" value={userDetails.optedIn} onSave={newValue => handleSave('opt', newValue)}/>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>

                            {formErrors.length === 0 && success && (
                                <CheckIcon />
                            )}
                            <br/>
                            <Button variant="outlined" color="primary" onClick={() => manageChangingPassword(!isChangingPassword)}>
                                Change Password?
                            </Button>

                            {isChangingPassword && (
                                <div>
                                    {loading ? 
                                        <>
                                            <br/>
                                            <LinearProgress /> 
                                        </>
                                        : (
                                        <>
                                            {formErrors.length > 0 && (
                                                <>
                                                <br/>
                                                <Alert severity="error" style={{textAlign: "left"}}> 
                                                    <AlertTitle>Error</AlertTitle>
                                                    The following errors occured trying to create a call:
                                                    <ul>
                                                        {formErrors.map((error,index) => (
                                                            <li key={index}>{error}</li>
                                                        ))}
                                                    </ul>
                                                </Alert>
                                                </>
                                            )}
                                        </>
                                    )}

                                    <div style={{ marginTop: '20px' }}>
                                        <TextField label="Current Password" type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <TextField error={passwordError} helperText={passwordError ? "Passwords do not match" : ""} label="New Password" type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                    
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <TextField error={passwordError} helperText={passwordError ? "Passwords do not match" : ""} label="Confirm Password" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                    </div>
                                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                        <Button variant="contained" color="primary" onClick={handlePasswordChange} disabled={loading} > 
                                            {loading ? <CircularProgress size={24} /> : "Save"}
                                        </Button>
                                        <Button variant="outlined" color="secondary" onClick={() => manageChangingPassword(false)}>Cancel</Button>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContent>
                </Card>
            </div>

            <br/>
        </GreyBox>
    );
}

export default MyProfile;